import { FC } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { translateRouterHref } from '../../hooks/useTranslateLink'
import TopHiringCompaniesLPDesktop from './components/TopHiringCompaniesLP/TopHiringCompaniesLPDesktop'
import { useState } from 'react'
import { apiFetchTopCompany, apiFetchHotJobs, getFreeCJob } from '../../service/api/home.api'
import { useEffect } from 'react'
import FeatureLPCandidate from './components/FeatureLPCandidate'
import HelpCareer from './components/HelpCareer'
import NewsLPDesktop from './components/NewsLPCandidate/NewsLPDesktop'
import DownloadLPCandidate from './components/DownloadLPCandidate'
import SuccessLPCandidate from './components/SuccessLPCandidate'
import { GoogleOneTapLogin } from '../../hooks/google-one-tap/GoogleOneTapLogin'
import { getIsClientMobile, getIsServer } from '../../utillities/getIsServer'
import { useStoreTokenAndFetchUser } from '../../hooks/authentication/useStoreTokenAndFetchUser'
import env from '../../env'
import { submitAuthGoogleOneTapAPI } from '../../service/api/login.api'
import { FORM_PRODUCT } from '../../hooks/useFromProduct'
import { Trans } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import SearchDreamJob from './components/SearchDreamJob/SearchDreamJob'
import Banners from './components/Banners/Banners'
import HotCategory from './components/HotCategory/HotCategory'
import HotJobs from './components/HotJobs/HotJobs'
import { getHotCategories } from './api/getHotCategories'

import LazyLoad from 'react-lazyload'
import { toSEOPathNameV2 } from '../../utillities/SEOUtilities'
import { LINK_URL_JOBS } from '../../utillities/LinkURL'
import { getJobForAnonymous } from '../../service/api/job.api'
import useClient from '../../hooks/useClient'

const LPCandidateWrapper: FC = () => {
  const client = useClient()
  const { currentLanguage, translateLink } = useTranslation()
  const [dataTopCompany, setDataTopCompany] = useState([])
  const [dataHotJobs, setDataHotJobs] = useState()
  const [dataHotCategories, setDataHotCategories] = useState()
  const [dataJobsFreeC, setDataJobsFreeC] = useState<any>()

  const storeTokenAndFetchUser = useStoreTokenAndFetchUser()

  const onFinish = (values) => {
    const name = values.title
    window.location.href = name ? toSEOPathNameV2({ search: name }, currentLanguage) : translateRouterHref(LINK_URL_JOBS, currentLanguage)
  }
  const fetchTopCompany = () => {
    apiFetchTopCompany().then((rs) => {
      const cloneData = JSON.parse(JSON.stringify(rs?.data.filter((item) => item.availabaleJobNumber > 0) || []))
      setDataTopCompany(cloneData)
    })
  }

  const fetchHotJobs = () => {
    apiFetchHotJobs().then((rs) => {
      if (rs.data) setDataHotJobs(rs.data.data)
    })
  }

  const fetchJobsFreeC = () => {
    return getFreeCJob().then((rs) => {
      setDataJobsFreeC(rs.data.data)
    })
  }

  useEffect(() => {
    fetchTopCompany()
    fetchHotJobs()
    fetchJobsFreeC()

    getHotCategories().then((rs) => {
      if (rs.data.result) setDataHotCategories(JSON.parse(rs.data.result))
    })
  }, [])

  const openNotificationWithIcon = (title, description, message) => {
    toast.error(description)
  }
  console.log(dataJobsFreeC, dataHotJobs)
  return (
    <div>
      <style>{`
        .grid-banner {
          background-color: #262626;
          display: grid;
          grid-template-columns: 50px 1fr 50px;
          grid-template-rows: 240px 271px 88px;
          width: 100%;
        }
        .banner-slick {
          grid-area: 1 / 1 / 4 / 4;
        }
        .banner-search {
          grid-area: 1 / 2 / 4 / 2;
          z-index: 10;
          pointer-events: none;
        }
        @media screen and (max-width: 768px) {
          .grid-banner {
            grid-template-columns: 17px 1fr 17px;
            grid-template-rows: 240px auto 88px;
          }
        }
      `}</style>
      <GoogleOneTapLogin
        onError={() => openNotificationWithIcon('error', 'Error', 'Cannot login.')}
        onSuccess={(response) => {
          const fromProduct = getIsClientMobile() ? FORM_PRODUCT.WEB_MOBILE : FORM_PRODUCT.WEB_DESKTOP
          return submitAuthGoogleOneTapAPI({ token: response.credential, from_product: fromProduct })
            .then((response) => {
              return storeTokenAndFetchUser(response.data.token).then(() => {
                window.location.href = translateLink('/jobs')
              })
            })
            .catch((error) => {
              handleErrorAPIFromRequest({
                error,
                callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription)
              })
            })
        }}
        googleAccountConfigs={{
          client_id: env.PUBLIC_GOOGLE_APP_ID,
          prompt_parent_id: !getIsServer() && window.outerWidth < 1023.98 ? null : 'content-google-one-tap-1'
        }}
      />
      {/* <div className="grid-banner">
        <div className="banner-slick">
          <TopSlider />
        </div>
        <div className="banner-search"> */}
      <SearchDreamJob onFinish={onFinish} />
      <TopHiringCompaniesLPDesktop slidersData={dataTopCompany} />
      <LazyLoad height={200}>
        <Banners />
      </LazyLoad>

      <LazyLoad height={200}>
        <HotJobs
          sliderData={dataHotJobs}
          HeadingTitle={
            <Trans i18nKey="page_home:lp_job_opportunity">
              <span className="high-light" />
            </Trans>
          }
        />
      </LazyLoad>

      <LazyLoad height={200}>
        <HotCategory sliderData={dataHotCategories} />
      </LazyLoad>
      <LazyLoad height={200}>
        <FeatureLPCandidate />
      </LazyLoad>
      <LazyLoad height={200}>
        <HelpCareer />
      </LazyLoad>
      <LazyLoad height={200}>
        <HotJobs
          sliderData={dataJobsFreeC}
          HeadingTitle={
            <Trans i18nKey="page_home:lp_jobs_from_freec">
              <span className="high-light" />
            </Trans>
          }
        />
      </LazyLoad>
      <LazyLoad height={200}>
        <NewsLPDesktop />
      </LazyLoad>
      <LazyLoad height={200}>
        <DownloadLPCandidate />
      </LazyLoad>
      <LazyLoad height={200}>
        <SuccessLPCandidate />
      </LazyLoad>

      <ToastContainer />
    </div>
  )
}
const handleErrorAPIFromRequest = ({ error, callbackAction }) => {
  const response = error?.response || {}
  const { status, data } = response

  if (!status || !data) return null
  const keys = []
  Object.keys(data?.messages || {}).forEach((key) => {
    if (key !== 'user_id' && key !== 'payload') {
      keys.push(...data?.messages[key])
    }
  })

  if (status === 422) {
    return toast.error(JSON.stringify(data?.messages[0]))
  }
  if (
    data.messages === 'This email does not exist, please check again!' ||
    data.messages === 'Email này không tồn tại, vui lòng kiểm tra lại!'
  ) {
    return callbackAction(<Trans i18nKey="notification:email_does_not_exist" />)
  }
  if (status === 400 || status === 401) {
    keys.forEach((element) => {
      switch (element) {
        case 'INVALID_CREDENTAILS':
        case 'ACCOUNT_NOT_EXIST':
          callbackAction(<Trans i18nKey="notification:validate_login_fail" />)
          break

        case 'WRONG_USER_TYPE':
          callbackAction(<Trans i18nKey="notification:validate_wrong_user_type" />)
          break

        case 'ACCOUNT_IS_BLOCKED':
        case 'COMPANY_IS_BLOCKED':
          callbackAction(<Trans i18nKey="notification:company_is_blocked" />, '')
          break
        case 'IS_NOT_BELONGS_TO_RECRUITER':
          callbackAction(<Trans i18nKey="notification:validate_belongs_to_recruiter" />)
          break
        case 'IS_NOT_BELONGS_TO_CANDIDATE':
          callbackAction(<Trans i18nKey="notification:validate_belongs_to_candidate" />)
          break

        default:
          callbackAction(<Trans i18nKey="notification:validate_login_fail" />)
          break
      }
    })
  }
}
export default LPCandidateWrapper
