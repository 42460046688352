import Client from '../../utillities/Client'
import { API_URL_GET_TOP_COMPANY, API_URL_GET_HOT_JOBS } from '../../utillities/APIConstant'
import { mapTopCompany } from '../mapping/home.mapping'

export const apiFetchTopCompany: () => Promise<any> = () => {
  return Client.get<any>(API_URL_GET_TOP_COMPANY, {}).then((rs) => mapTopCompany(rs.data))
}

export const apiFetchHotJobs: () => Promise<any> = () => {
  return Client.get(API_URL_GET_HOT_JOBS + '?items=60', {})
}

export const getFreeCJob = (): Promise<any> => {
  return Client.get('/api/v2/public/job_best_salary', {})
}
