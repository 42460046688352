import { FC } from 'react'
import Row from '../../../components/base/Grid/Row'
import Col from '../../../components/base/Grid/Col'
import { useTranslation } from '../../../hooks/useTranslation'
import Image from 'next/image'

import QR_CODE from '../assets/img/qr_code.webp'
import IMG_CHPLAY from '../assets/img/googleplay.webp'
import IMG_APPSTORE from '../assets/img/appstore.webp'
import IMG_DOWNLOAD_BACKGROUND_XXL from '../assets/img/mockup-app@2x.png'
import IMG_DOWNLOAD_BACKGROUND_LG from '../assets/img/mockup-app-lg.png'

import { Trans } from 'react-i18next'
import { LINK_URL_DOWNLOAD_APPLE, LINK_URL_DOWNLOAD_ANDROID } from '../../../utillities/LinkURL'

import FilledIcon from '../assets/img/filled_icon.svg'

const DownloadLPCandidate: FC = () => {
  const { translate } = useTranslation()
  return (
    <div className="download">
      <div className="download-right d-lg-none">
        <Image src={IMG_DOWNLOAD_BACKGROUND_LG} alt="img_download" width={671} height={625} />
      </div>
      <div className="wrapper-container">
        <Row>
          <Col xs={24} lg={11}>
            <div className="download-left">
              <h2 className="heading">
                <Trans i18nKey="page_home:lp_never_miss">
                  <span className="high-light" />
                </Trans>
              </h2>
              <div className="content">
                <div className="content__img">
                  <Image src={FilledIcon} alt="filled icon" width={24} height={24} />
                </div>
                <div>
                  <div className="title">{translate('page_home:search_jobs_on_the_go')}</div>
                  <div className="description">{translate('page_home:search_and_apply_for_jobs_through_your_phone')}</div>
                </div>
              </div>
              <div className="content">
                <div className="content__img">
                  <Image src={FilledIcon} alt="filled icon" width={24} height={24} />
                </div>
                <div>
                  <div className="title">{translate('page_home:lp_easily_create_cv')}</div>
                  <div className="description">{translate('page_home:lp_freec_provide_tool')}</div>
                </div>
              </div>
              <div className="d-flex align-items-center download-app">
                <div className="download-app__qr-code">
                  <Image src={QR_CODE} alt="qr_code" className="qr-code" width={100} height={100} />
                </div>
                <div>
                  <div className="download-app__chplay">
                    <a target="_blank" rel="noopener noreferrer" href={LINK_URL_DOWNLOAD_ANDROID}>
                      <Image src={IMG_CHPLAY} alt="ch_play" width={138} height={40} />
                    </a>
                  </div>
                  <div>
                    <a target="_blank" rel="noopener noreferrer" href={LINK_URL_DOWNLOAD_APPLE}>
                      <Image src={IMG_APPSTORE} alt="app_store" width={138} height={40} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="download-right d-none d-lg-block d-xxl-none">
        <Image src={IMG_DOWNLOAD_BACKGROUND_LG} alt="img_download" width={671} height={625} />
      </div>
      <div className="download-right d-none d-xxl-block">
        <Image src={IMG_DOWNLOAD_BACKGROUND_XXL} alt="img_download" width={976} height={647} />
      </div>
    </div>
  )
}

export default DownloadLPCandidate
